<template>
  <div class="composite-details">
    <Layout class="Layout" :hd="true" :ft="false">
      <div slot="le" class="hd-left" @click="$router.back()">
        <div></div>
        <span>文章详情</span>
      </div>
      <div class="underline underline-top"></div>

      <div class="details-content">
        <h3 class="details-title">
          {{ details.title }}
        </h3>
        <div class="pubdate">
          <div>
            <van-icon class="icon" size="14" name="clock-o" />
            <span>发布时间：{{ details.releaseTime }}</span>
          </div>
          <div class="type">{{ details.articleTypeName }}</div>
        </div>
        <div class="relation">
          <div>
            <van-icon class="icon" size="14" name="user-o" />
            <span>发布人：{{ details.createByRealName }}</span>
          </div>
        </div>

        <div class="relation">
          <div>
            <van-icon class="icon" size="14" name="phone-o" />
            <span>联系方式：{{ details.createByContact }}</span>
          </div>
        </div>

        <div class="relation">
          <div>
            <van-icon class="icon" size="14" name="eye-o" />
            <span>浏览量：{{ details.viewNum }}</span>
          </div>
        </div>
        <div
          class="article-contents markdown-body"
          v-html="details.content"
        ></div>
        <!-- <div class="button">立即咨询</div> -->
      </div>
      <div class="division"></div>
      <div class="comment">
        <div class="comment-top">
          <div class="left">评论 {{ details.messageCount }}</div>
          <div class="right" @click="consulting">
            <van-icon name="edit" color="#275b94" />立刻咨询
          </div>
        </div>
        <div class="underline">
          <div></div>
        </div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="暂无更多"
          @load="onLoad"
        >
          <div
            class="comment-item"
            v-for="(item, index) in messageList"
            :key="index"
          >
            <div class="portrait">
              <img :src="item.userAvatar" alt="" />
            </div>
            <div class="user-comment">
              <div class="user-container">
                <p class="user-name">{{ item.userName }}</p>
                <p class="comment-date">{{ item.messageTime }}</p>
              </div>
              <div class="comment-details">
                {{ item.messageContent }}
                <van-button
                  @click="Deletemessage(item.id, index)"
                  v-if="item.couldDeleted"
                  class="btn-item"
                  icon="delete-o"
                >
                </van-button>
              </div>
              <div v-if="item.messageReplyDTOS.length != 0 && !item.unfoldMore">
                <div
                  v-for="item1 in item.messageReplyDTOS"
                  :key="item1.id"
                  class="comment-reply"
                >
                  <span class="reply-name"
                    >{{ item1.replyUserRealName }}&nbsp;:</span
                  ><span class="reply-content"
                    >&nbsp;{{ item1.replyContent }}</span
                  >
                </div>
              </div>
              <replyList v-if="item.unfoldMore" :item="item"></replyList>
              <!-- <van-list
                v-if="item.unfoldMore"
                v-model="replyloading"
                :finished="replyfinished"
                finished-text="暂无更多"
                @load="replyMore(item.id)"
              >
                <div
                  v-for="item in replymessageList"
                  :key="item.replyMessageId"
                  class="comment-reply"
                >
                  <span class="reply-name">{{ item.replyUserName }}&nbsp;:</span
                  ><span class="reply-content"
                    >&nbsp;{{ item.replyContent }}</span
                  >
                </div>
              </van-list> -->

              <div
                class="unfoldMore"
                v-if="item.messageReplyDTOS.length != 0"
                @click="item.unfoldMore = !item.unfoldMore"
              >
                {{ item.unfoldMore ? '收起' : '更多回复' }}
              </div>
            </div>
          </div>
          <div class="comment-underline"></div>
        </van-list>
      </div>
      <!-- 发布评论 -->
      <van-popup
        style="width: 100vw"
        @close="inputText = ''"
        v-model="consultingDisplay"
        position="bottom"
        id="inputID"
      >
        <div v-if="consultingDisplay" class="comment-post">
          <van-field
            @click="inputClick"
            class="post-field"
            v-model="inputText"
            rows="2"
            autosize
            type="textarea"
            maxlength="100"
            placeholder="请输入留言"
            show-word-limit
          />
          <van-button
            class="post-btn"
            @click="sendOut"
            :disabled="!inputText.length"
            >发送</van-button
          >
        </div>
      </van-popup>
      <!-- <van-popup
        v-model="consultingDisplay"
        position="bottom"
        :style="{ height: '5%', width: '100%' }"
      >
        <input type="text" v-model.trim="inputText" />
        <button @click="sendOut">发送</button>
      </van-popup> -->
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/layout.vue'
import replyList from './replyList.vue'
import {
  getComprehensiveItem,
  getPublish,
  getMessageList,
  getReplyComments,
  deleteMessage,
  ArticlePageview
} from '@/api/comprehensive'
import { Dialog } from 'vant'
export default {
  name: '',
  components: {
    Layout,
    replyList
  },
  data() {
    return {
      details: {},
      // detailsTypes: '',
      consultingDisplay: false,
      inputText: '',
      finished: false,
      loading: false,
      pageNo: 1,
      pageSize: 10,
      messageList: []
      // replyloading: false,
      // replyfinished: false,
      // replypageNo: 1,
      // replypageSize: 10,
      // replymessageList: []
    }
  },
  computed: {
    // total() {
    //   return this.messageList.reduce((total, item) => {
    //     return total + item.replyMessageDTOs.length + 1
    //   }, 0)
    // }
  },
  created() {
    this.getComprehensiveItem(this.$route.params.id)
    ArticlePageview({
      articleId: this.$route.params.id
    }).then((res) => {
      console.log(res)
    })
    // this.getMessageList(this.$route.params.id)
  },
  mounted() {},
  methods: {
    inputClick() {
      document.querySelector('#inputID').scrollIntoView()
    },
    getComprehensiveItem(id) {
      getComprehensiveItem({
        serviceArticleId: id
      }).then((res) => {
        this.details = res.result
        // this.getType(this.details.type)
      })
    },
    // async replyMore(id) {
    //   try {
    //     // 1. 请求获取数据
    //     const result = await getReplyComments({
    //       pageNo: this.replypageNo,
    //       pageSize: this.replypageSize,
    //       messageId: id
    //     })

    //     const list = result.result.records
    //     console.log(list)
    //     // 2. 把请求结果数据放到 list 数组中

    //     // 数组的展开操作符，它会把数组元素依次传入
    //     this.replymessageList.push(...list)
    //     // 判断活动时间是否在当前时间段

    //     // 3. 本次数据加载结束之后要把加载状态设置为结束
    //     this.replyloading = false

    //     // 4. 判断数据是否全部加载完成
    //     if (result.result.records.length) {
    //       // 更新获取下一页数据的时间戳
    //       this.replypageNo++
    //     } else {
    //       // 没有数据了，将 finished 设置为 true，不再 load 加载更多了
    //       this.replyfinished = true
    //     }
    //   } catch (err) {
    //     // 请求失败了，loading 也需要关闭
    //     this.replyloading = false
    //   }
    // },
    // getMessageList(id) {
    //   getMessageList({
    //     articleId: id,
    //     pageNo: this.pageNo,
    //     pageSize: this.pageSize
    //   }).then((res) => {
    //     this.messageList = res.result.records
    //   })
    // },
    async onLoad() {
      try {
        // 1. 请求获取数据
        const result = await getMessageList({
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          articleId: this.$route.params.id
        })
        const list = result.result.records
        list.forEach((item) => {
          item.unfoldMore = false
        })
        // 2. 把请求结果数据放到 list 数组中

        // 数组的展开操作符，它会把数组元素依次传入
        this.messageList.push(...list)
        // 判断活动时间是否在当前时间段

        // 3. 本次数据加载结束之后要把加载状态设置为结束
        this.loading = false

        // 4. 判断数据是否全部加载完成
        if (result.result.records.length) {
          // 更新获取下一页数据的时间戳
          this.pageNo++
        } else {
          // 没有数据了，将 finished 设置为 true，不再 load 加载更多了
          this.finished = true
        }
      } catch (err) {
        // 请求失败了，loading 也需要关闭
        this.loading = false
      }
    },
    // getType(type) {
    //   switch (type) {
    //     case 1:
    //       this.detailsTypes = '创业帮扶'
    //       break
    //     case 2:
    //       this.detailsTypes = '一站注册'
    //       break6
    //     case 3:
    //       this.detailsTypes = '财税服务'
    //       break
    //     case 4:
    //       this.detailsTypes = '融资服务'
    //       break
    //     case 5:
    //       this.detailsTypes = '法律服务'
    //       break
    //     case 6:
    //       this.detailsTypes = '数字营销'
    //       break
    //     case 7:
    //       this.detailsTypes = '科技申报'
    //       break
    //     case 8:
    //       this.detailsTypes = '设计服务'
    //       break
    //     case 9:
    //       this.detailsTypes = '技术服务'
    //       break
    //     case 0:
    //       this.detailsTypes = '人力资源'
    //       break
    //   }
    // },
    consulting() {
      this.consultingDisplay = true
    },
    Deletemessage(id, index) {
      Dialog.confirm({
        message: '确认删除吗',
        width: '200px',
        confirmButtonColor: '#10955b'
      })
        .then(() => {
          deleteMessage({
            messageId: id
          }).then((res) => {
            this.messageList.splice(index, 1)
            this.details.messageCount--
          })
        })
        .catch(() => {
          return
        })
    },

    async sendOut() {
      this.$toast.loading({
        message: '评论中...',
        forbidClick: true, // 禁用背景点击
        duration: 0 // 持续时间，默认 2000，0 表示持续展示不关闭
      })

      await getPublish({
        articleCreateBy: this.details.createBy,
        articleId: this.$route.params.id,
        articleTitle: this.details.title,
        messageContent: this.inputText
      }).then((res) => {
        if (res.code == 200) {
          this.inputText = ''
          // this.details.messageCount++
          // this.messageList.unshift(res.result)

          this.getComprehensiveItem(this.$route.params.id)
          this.$toast.success('评论成功')
          this.consultingDisplay = false
        } else if (res.code == 500) {
          this.inputText = ''
          this.$toast.success(res.message)
          this.consultingDisplay = false
        }
      })

      // getPublish({
      //   articleCreateBy: this.details.createBy,
      //   articleId: this.$route.params.id,
      //   articleTitle: this.details.title,
      //   messageContent: this.inputText
      // }).then((res) => {
      //   console.log(res)
      //   this.consultingDisplay = false
      //   this.inputText = ''
      // })
    }
  }
}
</script>

<style lang="less" scoped>
@import './github-markdown.css';
.comment-post {
  width: 100vw;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
  .post-field {
    background-color: #ffffff;
  }
  .post-btn {
    margin-left: 10px;
    width: 100px;
    border: none;
    padding: 0;
    color: #10955b;
    &::before {
      display: none;
    }
  }
}
.composite-details {
  background-color: #fff;
  width: 100vw;
  // .Layout /deep/ .van-nav-bar {
  //   background-color: #fff;
  // }
  .Layout /deep/ .main {
    padding-bottom: 0;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
      color: #ffffff;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      letter-spacing: 0px;
    }
  }

  .details-title {
    font-size: 16px;
  }
  .underline {
    height: 1px;
    background: #dee0e8;
    &-top {
      position: fixed;
      top: 46;
      left: 0;
      right: 0;
    }
  }
  .details-content {
    padding: 16px 16px 30px 16px;
    .title {
      width: 343px;
      height: 50px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #12151b;
    }
    .pubdate,
    .relation {
      display: flex;
      justify-content: space-between;
      line-height: 24px;
      margin-top: 16px;
      img {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
      .icon {
        line-height: 24px;
        margin-right: 9px;
      }
      span {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: LEFT;
        color: #868a93;
        line-height: 24px;
        letter-spacing: 0px;
      }
      .type {
        width: 67px;
        height: 24px;
        text-align: center;
        border-radius: 12px;
        background: #e4f0ff;
        font-size: 11px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;

        color: #2083ff;
        line-height: 24px;
        letter-spacing: 0px;
      }
    }
    .relation {
      margin-top: 0 !important;
    }

    .article-contents {
      margin-top: 16px;

      // p {
      //   font-size: 14px;
      //   font-family: PingFang SC, PingFang SC-Medium;
      //   font-weight: 500;
      //   text-align: LEFT;
      //   color: #464a55;
      //   line-height: 21px;
      //   letter-spacing: 0px;
      // }

      // .img {
      //   margin: 16px 0;
      //   width: 343px;
      //   height: 193px;
      //   background: #c4c4c4;
      //   border-radius: 4px;
      //   img {
      //     width: 100%;
      //     height: 100%;
      //   }
      // }
    }
    .button {
      margin: 9px 0 0 32px;
      width: 279px;
      line-height: 40px;
      text-align: center;
      color: #ffffff;
      background: linear-gradient(90deg, #25c794, #20ce82, #14ab69 100%);
      border-radius: 8px;
      box-shadow: 0px 4px 10px 0px rgba(12, 162, 92, 0.3);
    }
  }
  .division {
    width: 100%;
    height: 13px;
    background-color: #f2f3f5;
  }
  .comment {
    background: #ffffff;
    padding: 0px 20px 20px 20px;
    .comment-top {
      height: 46px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        flex: 1;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: LEFT;
        color: #12151b;
      }
      .right {
        width: 100px;
        height: 16px;
        font-size: 14px;
        font-family: Arial, Arial-Regular;
        font-weight: 400;
        text-align: RIGHT;
        color: #275b94;
        letter-spacing: 0px;
      }
    }
    .underline {
      margin-bottom: 8px;
      position: relative;

      height: 1px;
      background: #c4c4c4;
      div {
        position: absolute;
        top: -1px;
        width: 40px;
        height: 2px;
        background: #10955b;
        border-radius: 4px;
      }
    }
  }
  .not-available {
    text-align: center;
  }
  .comment-item {
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
    .portrait {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: rgb(119, 119, 119);
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
    .user-comment {
      .unfoldMore {
        margin-right: 0;
        margin-left: auto;
        margin-top: 10px;
        text-align: center;
        width: 60px;
        line-height: 20px;
        background: #10955b;
        border-radius: 2em;
        color: #ffffff;
      }
      .btn-item {
        border: none;
        padding: 0;
        height: 30px;
        font-size: 13px;
        color: #868a93;
      }
      margin-left: 10px;
      width: 290px;
      overflow: hidden;
      .comment-reply {
        margin-top: 9px;
        margin-bottom: 3px;
        padding-left: 10px;
        width: 280px;
        line-height: 28px;
        background: #f2f3f5;
        border-radius: 1px;
        .reply-name {
          font-size: 13px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: LEFT;
          color: #275b94;
        }
        .reply-content {
          font-size: 13px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: LEFT;
          color: #464a55;
          word-break: break-all;
        }
      }
      .user-container {
        width: 290px;
        display: flex;
        justify-content: space-between;
        .user-name {
          width: 100px;
          height: 18px;
          font-size: 13px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: LEFT;
          color: #464a55;
        }
        .comment-date {
          font-size: 12px;
          font-family: SF Pro Display, SF Pro Display-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #868a93;
        }
      }
      .comment-details {
        word-break: break-all;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;
        width: 290px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: LEFT;
        color: #12151b;
        letter-spacing: 0px;
      }
    }
  }
  .comment-underline {
    width: 289px;
    margin-top: 16px;
    margin-left: 46px;
    border-bottom: 1px solid #c2c5cc;
  }
  /deep/ .van-overlay {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  /deep/ .van-popup {
    background-color: rgb(241, 241, 241) !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
      height: 80%;
      width: 280px;
      border: none;
      margin-left: 10px;
    }
    button {
      padding: 5px 20px;
      margin-right: 5px;
    }
  }
}
</style>