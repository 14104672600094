<template>
  <div>
    <compositeDetails v-if="!$store.state.user.elderModel"></compositeDetails>
    <compositeDetailsDetails v-else></compositeDetailsDetails>
  </div>
</template>

<script>
import compositeDetails from './composite-details.vue'
import compositeDetailsDetails from './composite-details-elder.vue'
export default {
  name: '',
  components: {
    compositeDetails,
    compositeDetailsDetails
  }
}
</script>

<style lang="less" scoped>
</style>
